import { useContext, useMemo, useState } from "react"
import FadeFromTop from "../../components/animations/FadeFromTop"
import PageContainer from "../../components/global/PageContainer"
import { colors } from "../../services/config/colors"
import { ButtonBase, Grid, Stack, useMediaQuery, useTheme } from "@mui/material"
import Title from "../../components/global/Title"
import Card from "../../components/global/Card"
import LifestyleCard from "../../components/footprint/LifestyleCard"
import infoPrimaryIcon from "../../assets/icons/info-primary.svg"
import BarChart from "../../components/global/BarChart"
import Text from "../../components/global/Text"
import PieChart from "../../components/global/PieChart"
import BarChartMultiple from "../../components/global/BarChartMultiple"
import BarChartLegendItem from "../../components/global/BarChartLegendItem"
import {
  capitalizeFirstCharacter,
  formatNumber,
  roundWithDecimalPlaces,
} from "../../services/utils/utils"
import { t } from "i18next"
import { MainContext } from "../../controllers/main"
import BenchmarkCard from "../../components/footprint/BenchmarkCard"
import laptopsBenchmarkImage from "../../assets/images/benchmarks/laptops-benchmark.png"
import flightsBenchmarkImage from "../../assets/images/benchmarks/flights-benchmark.png"
import treesBenchmarkImage from "../../assets/images/benchmarks/trees-benchmark.png"
import EmissionsCard from "../../components/footprint/EmissionsCard"
import homeIcon from "../../assets/icons/home.svg"
import shoppingIcon from "../../assets/icons/shopping.svg"
import transportIcon from "../../assets/icons/transport.svg"
import foodIcon from "../../assets/icons/food.svg"
import SelectMultiple from "../../components/global/SelectMultiple"
import SelectOption from "../../models/selectOption"
import ChartDataItem from "../../models/chartDataItem"
import AlertInfo from "../../components/global/AlertInfo"
import chevronLeftIcon from "../../assets/icons/chevron-left-small.svg"
import chevronRightIcon from "../../assets/icons/chevron-right-small.svg"
import { defaultTransition } from "../../services/config/constants"
import Select from "../../components/global/Select"
import footprintImages from "../../services/config/footprintImages"
import LineChart from "../../components/global/LineChart"
import months from "../../services/config/months"

const Footprint = () => {
  const theme = useTheme()
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"))
  const {
    dataLoading,
    footprintOverviewCardData,
    footprintOverviewCardsData,
    footprintBreakdownCardData,
    footprintByGroupCardData,
    groupsList,
    footprintBreakdownByCategoriesData,
  } = useContext(MainContext)

  // co2 emissions breakdown select
  const emissionsBreakdownOptions = [
    t("all"),
    t("home"),
    t("shopping"),
    t("transport"),
  ]
  const [
    emissionsBreakdownSelectedOption,
    setEmissionsBreakdownSelectedOption,
  ] = useState<number>(0)

  // categories select
  const categoriesOptions = [
    { id: "home", label: t("home") },
    { id: "shopping", label: t("shopping") },
    { id: "transport", label: t("transport") },
    { id: "food", label: t("food") },
  ]
  const [categoriesSelectedOptions, setCategoriesSelectedOptions] = useState<
    SelectOption[]
  >([])

  // filtered data for group emissions chart
  const groupEmissionsChartData: ChartDataItem[][] = useMemo(() => {
    const dataToReturn = []

    if (dataLoading) {
      return [[]]
    }

    if (
      !categoriesSelectedOptions.length ||
      categoriesSelectedOptions.some((option) => option.id === "home")
    ) {
      dataToReturn.push(
        footprintByGroupCardData
          .filter((item) => item.category === "home")
          .filter(
            (item) =>
              !item.groupId ||
              item.groupId === "none" ||
              item.groupId === "group_default" ||
              groupsList.some((group) => group.id === item.groupId)
          )
          .map((item) => {
            return {
              label:
                !item.groupId ||
                item.groupId === "none" ||
                item.groupId === "group_default"
                  ? t("no_group")
                  : groupsList.find((group) => group.id === item.groupId)!.name,
              value: item.categoryEmissionAmount,
              backgroundColor: colors.categories.home,
            }
          })
      )
    }
    if (
      !categoriesSelectedOptions.length ||
      categoriesSelectedOptions.some((option) => option.id === "shopping")
    ) {
      dataToReturn.push(
        footprintByGroupCardData
          .filter((item) => item.category === "lifestyle")
          .filter(
            (item) =>
              !item.groupId ||
              item.groupId === "none" ||
              item.groupId === "group_default" ||
              groupsList.some((group) => group.id === item.groupId)
          )
          .map((item) => {
            return {
              label:
                !item.groupId ||
                item.groupId === "none" ||
                item.groupId === "group_default"
                  ? t("no_group")
                  : groupsList.find((group) => group.id === item.groupId)!.name,
              value: item.categoryEmissionAmount,
              backgroundColor: colors.categories.shopping,
            }
          })
      )
    }
    if (
      !categoriesSelectedOptions.length ||
      categoriesSelectedOptions.some((option) => option.id === "transport")
    ) {
      dataToReturn.push(
        footprintByGroupCardData
          .filter((item) => item.category === "transport")
          .filter(
            (item) =>
              !item.groupId ||
              item.groupId === "none" ||
              item.groupId === "group_default" ||
              groupsList.some((group) => group.id === item.groupId)
          )
          .map((item) => {
            return {
              label:
                !item.groupId ||
                item.groupId === "none" ||
                item.groupId === "group_default"
                  ? t("no_group")
                  : groupsList.find((group) => group.id === item.groupId)!.name,
              value: item.categoryEmissionAmount,
              backgroundColor: colors.categories.transport,
            }
          })
      )
    }
    if (
      !categoriesSelectedOptions.length ||
      categoriesSelectedOptions.some((option) => option.id === "food")
    ) {
      dataToReturn.push(
        footprintByGroupCardData
          .filter((item) => item.category === "diet")
          .filter(
            (item) =>
              !item.groupId ||
              item.groupId === "none" ||
              item.groupId === "group_default" ||
              groupsList.some((group) => group.id === item.groupId)
          )
          .map((item) => {
            return {
              label:
                !item.groupId ||
                item.groupId === "none" ||
                item.groupId === "group_default"
                  ? t("no_group")
                  : groupsList.find((group) => group.id === item.groupId)!.name,
              value: item.categoryEmissionAmount,
              backgroundColor: colors.categories.food,
            }
          })
      )
    }

    return dataToReturn
  }, [
    dataLoading,
    footprintByGroupCardData,
    categoriesSelectedOptions,
    groupsList,
  ])

  // group emissions current page (when groups are too many)
  const maxGroupsPerPage = 5
  const [groupEmissionsPage, setGroupEmissionsPage] = useState<number>(0)

  // info alerts
  const [footprintComparisonAlertOpen, setFootprintComparisonAlertOpen] =
    useState<boolean>(false)
  const [teamEmissionsAlertOpen, setTeamEmissionsAlertOpen] =
    useState<boolean>(false)
  const [co2BreakdownAlertOpen, setCo2BreakdownAlertOpen] =
    useState<boolean>(false)
  const [co2ByCategoriesAlertOpen, setCo2ByCategoriesAlertOpen] =
    useState<boolean>(false)

  // data to display EmissionsCard(s)
  const emissionsCardsList = useMemo(() => {
    if (!footprintBreakdownCardData.length) {
      return []
    }

    switch (emissionsBreakdownSelectedOption) {
      case 0:
        return [
          {
            icon: homeIcon,
            title: t("home"),
            color: colors.categories.homeText,
            backgroundColor: colors.categories.home,
            amount: footprintBreakdownCardData.find(
              (item) => item.category === "home"
            )!.categoryEmissionAmount,
            percentage: footprintBreakdownCardData.find(
              (item) => item.category === "home"
            )!.categoryEmissionPercentage,
          },
          {
            icon: transportIcon,
            title: t("transport"),
            color: colors.categories.transportText,
            backgroundColor: colors.categories.transport,
            amount: footprintBreakdownCardData.find(
              (item) => item.category === "transport"
            )!.categoryEmissionAmount,
            percentage: footprintBreakdownCardData.find(
              (item) => item.category === "transport"
            )!.categoryEmissionPercentage,
          },
          {
            icon: shoppingIcon,
            title: t("shopping"),
            color: colors.categories.shoppingText,
            backgroundColor: colors.categories.shopping,
            amount: footprintBreakdownCardData.find(
              (item) => item.category === "lifestyle"
            )!.categoryEmissionAmount,
            percentage: footprintBreakdownCardData.find(
              (item) => item.category === "lifestyle"
            )!.categoryEmissionPercentage,
          },
          {
            icon: foodIcon,
            title: t("food"),
            color: colors.categories.foodText,
            backgroundColor: colors.categories.food,
            amount: footprintBreakdownCardData.find(
              (item) => item.category === "diet"
            )!.categoryEmissionAmount,
            percentage: footprintBreakdownCardData.find(
              (item) => item.category === "diet"
            )!.categoryEmissionPercentage,
          },
        ]
      case 1:
        return footprintBreakdownByCategoriesData
          .filter((item) => item.category === "home")
          .map((item) => {
            return {
              icon: (footprintImages as any)[item.sector],
              title: capitalizeFirstCharacter(t(item.sector)),
              color: colors.categories.homeText,
              backgroundColor: colors.categories.home,
              amount: item.sectorEmissionAmount,
              percentage: item.sectorEmissionPercentage,
            }
          })
      case 2:
        return footprintBreakdownByCategoriesData
          .filter(
            (item) =>
              item.category === "lifestyle" &&
              item.sector !== "electronicAppliance" &&
              item.sector !== "domesticAppliances" &&
              item.sector !== "otherExclDomestic"
          )
          .map((item) => {
            return {
              icon: (footprintImages as any)[item.sector],
              title: t(item.sector),
              color: colors.categories.shoppingText,
              backgroundColor: colors.categories.shopping,
              amount: item.sectorEmissionAmount,
              percentage: item.sectorEmissionPercentage,
            }
          })
      case 3:
        return footprintBreakdownByCategoriesData
          .filter((item) => item.category === "transport")
          .map((item) => {
            return {
              icon: (footprintImages as any)[item.sector],
              title: capitalizeFirstCharacter(t(item.sector)),
              color: colors.categories.transportText,
              backgroundColor: colors.categories.transport,
              amount: item.sectorEmissionAmount,
              percentage: item.sectorEmissionPercentage,
            }
          })
      default:
        return []
    }
  }, [footprintBreakdownCardData, emissionsBreakdownSelectedOption])

  return (
    <PageContainer
      title={t("carbon_footprint")}
      breadcrumbs={[{ title: t("carbon_footprint") }]}
      datePickerDisabled
    >
      {/* footprint overview overtime */}
      {dataLoading ||
      footprintOverviewCardData.filter((item) => item.activityDate).length ? (
        <FadeFromTop>
          <Card style={{ overflowX: "scroll" }}>
            <Stack gap={3} style={{ minWidth: 880 }}>
              <Title>{t("carbon_footprint_overview")}</Title>
              <div
                style={{
                  width: "100%",
                  height: 230,
                  position: "relative",
                }}
              >
                <LineChart
                  data={[
                    {
                      label: t("cfp_completed"),
                      data: footprintOverviewCardData
                        .filter((item) => item.activityDate)
                        .map((item) => {
                          return {
                            label: `${t(
                              months[new Date(item.activityDate).getMonth()]
                            )} ${new Date(item.activityDate).getFullYear()}`,
                            value: item.activityAmount,
                            backgroundColor: colors.categories.episodes,
                          }
                        }),
                    },
                  ]}
                />
              </div>
            </Stack>
          </Card>
        </FadeFromTop>
      ) : null}
      {/* lifestyle, co2 reduction and did you know */}
      <FadeFromTop delay={0.05} style={{ marginTop: 24 }}>
        <Stack direction={{ lg: "column", xl: "row" }} gap={3}>
          {/* lifestyle */}
          <Card gap={2} width={matchesXL ? "calc(100% / 3 - 16px)" : "100%"}>
            <Title>{t("carbon_footprint_lifestyle")}</Title>
            <Title
              fontSize={12}
              lineHeight="14px"
              color={colors.primary}
              style={{ marginTop: -12 }}
            >
              {t("employees")}
            </Title>
            <LifestyleCard
              title={t("cfp_lifestyle_completed")}
              value={
                footprintOverviewCardsData
                  ? `${formatNumber(
                      footprintOverviewCardsData?.footprintUsers
                    )} / ${formatNumber(
                      footprintOverviewCardsData?.totalUsers
                    )}`
                  : t("no_data")
              }
            />
            <LifestyleCard
              title={t("average_cfp_lifestyle_emissions")}
              value={
                footprintOverviewCardsData
                  ? `${formatNumber(
                      footprintOverviewCardsData?.avgEmissionAmount
                    )} ${t("tons_of_co2e")}`
                  : t("no_data")
              }
            />
            <LifestyleCard
              title={t("cfp_range")}
              value={
                footprintOverviewCardsData
                  ? `${formatNumber(
                      footprintOverviewCardsData?.minEmissionAmount
                    )} - ${formatNumber(
                      footprintOverviewCardsData?.maxEmissionAmount
                    )} ${t("tons")}`
                  : t("no_data")
              }
            />
            {/* <Button primary disabled size="normal">
              {t("view_more")}
            </Button> */}
          </Card>
          {/* co2 reduction */}
          <Card gap={0} width={matchesXL ? "calc(100% / 3 - 16px)" : "100%"}>
            <ButtonBase
              title="Info"
              style={{
                width: 28,
                height: 28,
                position: "absolute",
                right: 24,
                borderRadius: "100%",
              }}
              onClick={() => {
                setFootprintComparisonAlertOpen(true)
              }}
            >
              <img
                src={infoPrimaryIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </ButtonBase>
            <Title style={{ width: "calc(100% - 52px)" }}>
              {t("carbon_footpint_comparison")}
            </Title>
            <Text
              fontSize={12}
              fontWeight={500}
              color={colors.primary}
              style={{ marginTop: 20 }}
            >
              {capitalizeFirstCharacter(t("tons"))}
            </Text>
            <div
              style={{
                width: "100%",
                height: 231,
                position: "relative",
                marginTop: 10,
              }}
            >
              <BarChart
                maxRotation={0}
                data={
                  dataLoading
                    ? [{ label: "", value: 0 }]
                    : [
                        {
                          label: [
                            t("paris_agreement").slice(
                              0,
                              t("paris_agreement").lastIndexOf(" ")
                            ),
                            t("paris_agreement").slice(
                              t("paris_agreement").lastIndexOf(" ") + 1
                            ),
                          ],
                          value: footprintOverviewCardsData
                            ? footprintOverviewCardsData.parisAgreementEmissionAmount
                            : 0,
                        },
                        {
                          label: [
                            t("world_average").slice(
                              0,
                              t("world_average").lastIndexOf(" ")
                            ),
                            t("world_average").slice(
                              t("world_average").lastIndexOf(" ") + 1
                            ),
                          ],
                          value: footprintOverviewCardsData
                            ? footprintOverviewCardsData.worldEmissionAmount
                            : 0,
                        },
                        {
                          label: [
                            t("team_users_average").slice(
                              0,
                              t("team_users_average").lastIndexOf(" ")
                            ),
                            t("team_users_average").slice(
                              t("team_users_average").lastIndexOf(" ") + 1
                            ),
                          ],
                          value: footprintOverviewCardsData
                            ? footprintOverviewCardsData.avgEmissionAmount
                            : 0,
                        },
                      ]
                }
                xTicksFontSize={12}
                xTicksFontWeight={400}
                maxBarThickness={35}
              />
            </div>
          </Card>
          {/* total team emissions */}
          <Card gap={0} width={matchesXL ? "calc(100% / 3 - 16px)" : "100%"}>
            <ButtonBase
              title="Info"
              style={{
                width: 28,
                height: 28,
                position: "absolute",
                right: 24,
                borderRadius: "100%",
              }}
              onClick={() => {
                setTeamEmissionsAlertOpen(true)
              }}
            >
              <img
                src={infoPrimaryIcon}
                style={{ width: "100%", height: "100%" }}
              />
            </ButtonBase>
            <Title style={{ width: "calc(100% - 52px)" }}>
              {t("total_team_emissions")}
            </Title>
            <Stack alignItems="center" style={{ width: "100%" }}>
              <Title
                fontSize={24}
                lineHeight="28px"
                color={colors.primary}
                shouldLoad
                style={{ marginTop: 16 }}
              >
                {footprintBreakdownCardData && footprintBreakdownCardData[0]
                  ? formatNumber(
                      footprintBreakdownCardData[0].totalEmissionAmount
                    )
                  : t("no_data")}
              </Title>
              {footprintBreakdownCardData && footprintBreakdownCardData[0] ? (
                <Text fontSize={13}>{t("tons_of_co2e")}</Text>
              ) : (
                <div style={{ height: 17.5 }} />
              )}
              {footprintBreakdownCardData && footprintBreakdownCardData[0] ? (
                <div
                  className="center"
                  style={{
                    width: "auto",
                    height: 20,
                    paddingInline: 14,
                    backgroundColor: colors.backgroundLight,
                    borderRadius: 8,
                    marginTop: 5,
                  }}
                >
                  <Title fontSize={12} color={colors.textSecondary}>
                    {t("equals_to")}
                  </Title>
                </div>
              ) : (
                <div style={{ height: 20 }} />
              )}
              <BenchmarkCard
                title={`${
                  footprintBreakdownCardData && footprintBreakdownCardData[0]
                    ? footprintBreakdownCardData[0]
                        .totalEmissionAsLaptopCharges >= 100000
                      ? formatNumber(
                          Math.round(
                            footprintBreakdownCardData[0]
                              .totalEmissionAsLaptopCharges / 1000
                          )
                        ) + "K"
                      : formatNumber(
                          footprintBreakdownCardData[0]
                            .totalEmissionAsLaptopCharges
                        )
                    : 0
                } ${t("laptops")}`}
                description={t("laptops_description")}
                image={laptopsBenchmarkImage}
                style={{ marginTop: 18 }}
              />
              <BenchmarkCard
                title={`${
                  footprintBreakdownCardData && footprintBreakdownCardData[0]
                    ? footprintBreakdownCardData[0]
                        .totalEmissionAsLondonNYFlights >= 100000
                      ? formatNumber(
                          Math.round(
                            footprintBreakdownCardData[0]
                              .totalEmissionAsLondonNYFlights / 1000
                          )
                        ) + "K"
                      : formatNumber(
                          footprintBreakdownCardData[0]
                            .totalEmissionAsLondonNYFlights
                        )
                    : 0
                } ${t("flights")}`}
                description={t("flights_description")}
                image={flightsBenchmarkImage}
                style={{ marginTop: 10 }}
              />
              <BenchmarkCard
                title={`${
                  footprintBreakdownCardData && footprintBreakdownCardData[0]
                    ? footprintBreakdownCardData[0].totalEmissionAsTrees >=
                      100000
                      ? formatNumber(
                          Math.round(
                            footprintBreakdownCardData[0].totalEmissionAsTrees /
                              1000
                          )
                        ) + "K"
                      : formatNumber(
                          footprintBreakdownCardData[0].totalEmissionAsTrees
                        )
                    : 0
                } ${t("trees")}`}
                description={t("trees_description")}
                image={treesBenchmarkImage}
                style={{ marginTop: 10 }}
              />
            </Stack>
          </Card>
        </Stack>
      </FadeFromTop>
      {/* co2 emissions breakdown */}
      <FadeFromTop delay={0.1} style={{ marginTop: 24 }}>
        <Card gap={0}>
          <ButtonBase
            title="Info"
            style={{
              width: 28,
              height: 28,
              position: "absolute",
              right: 24,
              borderRadius: "100%",
            }}
            onClick={() => {
              setCo2BreakdownAlertOpen(true)
            }}
          >
            <img
              src={infoPrimaryIcon}
              style={{ width: "100%", height: "100%" }}
            />
          </ButtonBase>
          <Title style={{ maxWidth: "90%" }}>
            {t("co2_emissions_breakdown")}
          </Title>
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "100%",
              height: 69,
              position: "relative",
              marginTop: 6,
            }}
          >
            <Text fontSize={22} fontWeight={500}>
              {t("total_emissions")}
            </Text>
            {footprintBreakdownByCategoriesData.length ? (
              <Stack style={{ gap: 6, position: "absolute", right: 0 }}>
                <Text
                  fontSize={14}
                  lineHeight="14px"
                  fontWeight={500}
                  color={colors.primary}
                >
                  {t("show")}
                </Text>
                <Select
                  options={emissionsBreakdownOptions}
                  selectedOption={emissionsBreakdownSelectedOption}
                  setSelectedOption={setEmissionsBreakdownSelectedOption}
                  style={{ width: 160 }}
                  popoverWidth={160}
                  disabled={footprintBreakdownCardData[0] ? false : true}
                />
              </Stack>
            ) : null}
          </Stack>
          <Stack
            direction={matchesXL ? "row" : "column"}
            gap={5}
            alignItems="center"
            style={{
              width: "100%",
              marginTop: emissionsCardsList.length > 4 && matchesXL ? 24 : 8,
            }}
          >
            <div
              className="center"
              style={{
                width: 310,
                minWidth: 310,
                height: "100%",
                minHeight: 310,
                position: "relative",
              }}
            >
              <div style={{ width: 400, height: 400, position: "absolute" }}>
                <PieChart
                  data={
                    footprintBreakdownCardData && footprintBreakdownCardData[0]
                      ? emissionsCardsList.map((item) => {
                          return {
                            label: item.title,
                            value: item.amount,
                            backgroundColor: item.backgroundColor,
                            color: item.color,
                          }
                        })
                      : []
                  }
                  skeletonDimension={400}
                />
              </div>
              {!dataLoading && (
                <Stack
                  style={{ position: "absolute" }}
                  alignItems="center"
                  gap={0.25}
                >
                  <Title fontSize={36} lineHeight="40px">
                    {footprintBreakdownCardData[0]
                      ? emissionsBreakdownSelectedOption === 0
                        ? formatNumber(
                            roundWithDecimalPlaces(
                              footprintBreakdownCardData[0].totalEmissionAmount,
                              0
                            )
                          )
                        : formatNumber(
                            roundWithDecimalPlaces(
                              footprintBreakdownByCategoriesData.find((item) =>
                                emissionsBreakdownSelectedOption === 1
                                  ? item.category === "home"
                                  : emissionsBreakdownSelectedOption === 2
                                  ? item.category === "lifestyle"
                                  : item.category === "transport"
                              )!.categoryEmissionAmount,
                              0
                            )
                          )
                      : t("no_data")}
                  </Title>
                  {footprintBreakdownCardData[0] ? (
                    <Text
                      fontSize={12}
                      lineHeight="14px"
                      fontWeight={500}
                      color={colors.textSecondary}
                    >
                      {capitalizeFirstCharacter(t("tons_of_co2e"))}
                    </Text>
                  ) : null}
                </Stack>
              )}
            </div>
            {emissionsCardsList.length ? (
              <Grid container spacing={3} columns={2}>
                {emissionsCardsList.map((item, index) => (
                  <Grid key={index} item xs={1}>
                    <EmissionsCard
                      icon={item.icon}
                      title={item.title}
                      color={item.backgroundColor}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid container spacing={3} columns={2}>
                <Grid item xs={1}>
                  <EmissionsCard
                    icon=""
                    title=""
                    color=""
                    amount={0}
                    percentage={0}
                  />
                </Grid>
                <Grid item xs={1}>
                  <EmissionsCard
                    icon=""
                    title=""
                    color=""
                    amount={0}
                    percentage={0}
                  />
                </Grid>
                <Grid item xs={1}>
                  <EmissionsCard
                    icon=""
                    title=""
                    color=""
                    amount={0}
                    percentage={0}
                  />
                </Grid>
                <Grid item xs={1}>
                  <EmissionsCard
                    icon=""
                    title=""
                    color=""
                    amount={0}
                    percentage={0}
                  />
                </Grid>
              </Grid>
            )}
          </Stack>
        </Card>
      </FadeFromTop>
      {/* co2 emissions by group */}
      {footprintByGroupCardData.some((item) => item.groupId) || dataLoading ? (
        <FadeFromTop delay={0.15} style={{ marginTop: 24 }}>
          <Card style={{ overflowX: "scroll" }}>
            <Stack gap={3} style={{ minWidth: 880, position: "relative" }}>
              <ButtonBase
                title="Info"
                style={{
                  width: 28,
                  height: 28,
                  position: "absolute",
                  right: 24,
                  borderRadius: "100%",
                }}
                onClick={() => {
                  setCo2ByCategoriesAlertOpen(true)
                }}
              >
                <img
                  src={infoPrimaryIcon}
                  style={{ width: "100%", height: "100%" }}
                />
              </ButtonBase>
              <Title>{t("co2_emissions_by_categories")}</Title>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                style={{
                  width: "100%",
                  height: 69,
                  position: "relative",
                }}
              >
                {/* controls */}
                {groupEmissionsChartData[0].length > maxGroupsPerPage ? (
                  <Stack gap={1}>
                    <Title fontSize={16} shouldLoad>
                      {t("all_groups")} ({groupEmissionsChartData[0].length})
                    </Title>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <ButtonBase
                        disabled={groupEmissionsPage === 0}
                        style={{
                          width: 26,
                          height: 26,
                          borderRadius: "100%",
                        }}
                        onClick={() => {
                          setGroupEmissionsPage((current) => (current -= 1))
                        }}
                      >
                        <img src={chevronLeftIcon} alt="" />
                      </ButtonBase>
                      {new Array(
                        Math.ceil(
                          groupEmissionsChartData[0].length / maxGroupsPerPage
                        )
                      )
                        .fill(null)
                        .map((item, index) => (
                          <ButtonBase
                            key={index}
                            disabled={groupEmissionsPage === index}
                            style={{
                              width: 26,
                              height: 26,
                              borderRadius: "100%",
                              backgroundColor:
                                groupEmissionsPage === index
                                  ? colors.primary
                                  : colors.backgroundWhite,
                              fontSize: 12,
                              color:
                                groupEmissionsPage === index
                                  ? colors.textWhite
                                  : colors.text,
                              transition: defaultTransition,
                            }}
                            onClick={() => {
                              setGroupEmissionsPage(index)
                            }}
                          >
                            {index + 1}
                          </ButtonBase>
                        ))}
                      <ButtonBase
                        disabled={
                          groupEmissionsPage + 1 ===
                          Math.ceil(
                            groupEmissionsChartData[0].length / maxGroupsPerPage
                          )
                        }
                        style={{
                          width: 26,
                          height: 26,
                          borderRadius: "100%",
                        }}
                        onClick={() => {
                          setGroupEmissionsPage((current) => (current += 1))
                        }}
                      >
                        <img src={chevronRightIcon} alt="" />
                      </ButtonBase>
                    </Stack>
                  </Stack>
                ) : null}
                <Stack style={{ gap: 6, position: "absolute", right: 0 }}>
                  <Text
                    fontSize={14}
                    lineHeight="14px"
                    fontWeight={500}
                    color={colors.primary}
                  >
                    {t("show")}
                  </Text>
                  <SelectMultiple
                    options={categoriesOptions}
                    selectedOptions={categoriesSelectedOptions}
                    setSelectedOptions={setCategoriesSelectedOptions}
                    allLabel={
                      t("all_categories").charAt(0).toUpperCase() +
                      t("all_categories").slice(1).toLowerCase()
                    }
                    style={{ width: 160 }}
                    popoverWidth={160}
                  />
                </Stack>
              </Stack>
              <Text
                fontSize={12}
                fontWeight={500}
                color={colors.primary}
                style={{ marginTop: -24 }}
              >
                {capitalizeFirstCharacter(t("tons"))}
              </Text>
              <div style={{ width: "100%", height: 250, position: "relative" }}>
                <BarChartMultiple
                  data={groupEmissionsChartData.map((item) =>
                    item.slice(
                      groupEmissionsPage * maxGroupsPerPage,
                      groupEmissionsPage * maxGroupsPerPage + maxGroupsPerPage
                    )
                  )}
                  maxBarThickness={25}
                  xTicksFontSize={14}
                  xTicksFontWeight={500}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: 17,
                  position: "relative",
                }}
              >
                <Stack
                  direction="row"
                  gap={2}
                  alignItems="center"
                  style={{ position: "absolute", right: 0 }}
                >
                  <BarChartLegendItem
                    label={t("home")}
                    color={colors.categories.home}
                  />
                  <BarChartLegendItem
                    label={t("shopping")}
                    color={colors.categories.shopping}
                  />
                  <BarChartLegendItem
                    label={t("transport")}
                    color={colors.categories.transport}
                  />
                  <BarChartLegendItem
                    label={t("food")}
                    color={colors.categories.food}
                  />
                </Stack>
              </div>
            </Stack>
          </Card>
        </FadeFromTop>
      ) : null}
      {/* alerts */}
      <AlertInfo
        open={footprintComparisonAlertOpen}
        setOpen={setFootprintComparisonAlertOpen}
        title={t("carbon_footpint_comparison")}
        description={t("carbon_footpint_comparison_description")}
      />
      <AlertInfo
        open={teamEmissionsAlertOpen}
        setOpen={setTeamEmissionsAlertOpen}
        title={t("total_team_emissions")}
        description={t("total_team_emissions_description")}
      />
      <AlertInfo
        open={co2BreakdownAlertOpen}
        setOpen={setCo2BreakdownAlertOpen}
        title={t("co2_emissions_breakdown")}
        description={t("co2_emissions_breakdown_description")}
      />
      <AlertInfo
        open={co2ByCategoriesAlertOpen}
        setOpen={setCo2ByCategoriesAlertOpen}
        title={t("co2_emissions_by_categories")}
        description={t("co2_emissions_by_categories_description")}
      />
    </PageContainer>
  )
}

export default Footprint
